<template>
  <div>
    <b-overlay
      :show="LOADER"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Update Password">
        <validation-observer
          ref="updatePasswordValidator"
        >
          <b-form @submit.prevent="onSubmit">
            <b-form-group
              label="Old Password"
              label-for="old_password"
            >
              <validation-provider
                v-slot="{ errors }"
                name="old password"
                rules="required|min:6"
              >
                <b-form-input
                  id="old_password"
                  v-model="form.old_password"
                  name="password"
                  placeholder="Enter old password"
                />
                <span class="text-danger error-msg">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required|min:6"
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  name="password"
                  placeholder="Enter password"
                />
                <span class="text-danger error-msg">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Confirm password"
              label-for="password_confirmation"
            >
              <validation-provider
                v-slot="{ errors }"
                name="confirm password"
                rules="required|confirmed:password|min:6"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="form.password_confirmation"
                  name="password_confirmation"
                  placeholder="Confrim password"
                />
                <span class="text-danger error-msg">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                type="submit"
              >
                Update Password
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const appModule = createNamespacedHelpers('app')
const userModule = createNamespacedHelpers('user')
export default {

  data() {
    return {
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed: {
    ...appModule.mapState(['LOADER']),
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...userModule.mapActions(['UPDATE_PASSWORD']),
    onSubmit() {
      this.$refs.updatePasswordValidator.validate().then(async success => {
        if (success) {
          try {
            this.UPDATE_LOADER(true)
            const resp = await this.UPDATE_PASSWORD(this.form)
            if (resp) {
              this.resetForm()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Password updated!',
                  icon: 'PlusIcon',
                  variant: 'success',
                  text: 'Password has been updated successfully!',
                },
              })
              this.$nextTick(() => {
                this.$refs.updatePasswordValidator.reset()
              })
            }
            this.UPDATE_LOADER(false)
          } catch (error) {
            this.UPDATE_LOADER(false)
          }
        }
      })
    },
    resetForm() {
      this.form.old_password = ''
      this.form.password = ''
      this.form.password_confirmation = ''
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
